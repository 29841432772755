<template>
    <div class="complaint">
        <BackBar :pageTit="headTit" />
        <div class="complaint_main">
            <div class="complaint_wrap">
                <div class="complaint_case">
                    <div class="tit">投诉原因</div>
                    <div class="complaint_type">
                        <div v-for="(item,index) of cType" :key="'tag'+ item.id" @click="tag_click(index)" :class="{on:index==current}">{{item.title}}</div>
                    </div>
                </div>
                <div class="describe">
                    <div class="tit">描述</div>
                    <div class="describe_con">
                        <textarea placeholder="详细描述你的投诉内容（必填）" v-model="texts"></textarea>
                        <div class="upload">
                            <van-uploader v-model="fileList" multiple :max-count="3">
                                <div class="uploadPic"></div>
                            </van-uploader>
                        </div>
                    </div>
                </div>
                <div class="contact">
                    <div class="tit">联系方式</div>
                    <input placeholder="请留下你的联系方式" v-model="lxType">
                </div>
            </div>
            <div class="tjBtn" @click="sub">提交</div>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import BackBar from "@/components/BackBar.vue";
import Vue from 'vue'
import Api from '@/api'
export default {
    name:'Complaint',
    data(){
        return{
            headTit:'投诉',
            cType:[],
            current:1,
            texts:'', //文字描述
            fileList: [], //图片
            lxType:'', //联系方式
        }
    },
    components: {
        BackBar,
    },
    mounted(){
        var that=this
        Api.go('complaint_type',false,(res)=>{
            that.cType=res
        })
        if(this.$route.params.id>0){
            this.texts='投诉帖子【'+this.$route.params.id+'】：';
        }
    },
    methods:{
        sub(){
            var rr={
                tel:this.lxType,
                msg:this.texts,
                typeid:this.cType[this.current].id,
                img0:this.fileList.length>0?this.fileList[0].content:'',
                img1:this.fileList.length>1?this.fileList[1].content:'',
                img2:this.fileList.length>2?this.fileList[2].content:''
            }
            var that=this;
            Api.go('new_complaint',rr,(res)=>{
                 Vue.prototype.$toast('提交成功')
                  that.$router.back(-1)
            })
        },
        tag_click(index){
            this.current = index
        }
    }
}
</script>
<style lang="less" scoped>
    .complaint_wrap{
        background: #fff;
        padding: 0 .32rem;
        .tit{
            font-size: .32rem;
            color: #333;
            font-weight: bold;
        }
        .complaint_case{
            padding: .24rem 0 .36rem;
            border-bottom: 1px solid rgba(0, 0, 0, .1);
            .complaint_type{
                display: flex;
                flex-wrap: wrap;
                div{
                    height: .48rem;
                    line-height: .48rem;
                    background: #EFEDED;
                    border-radius: .24rem;
                    padding: 0 .24rem;
                    font-size: .28rem;
                    color: #333;
                    margin-right: .24rem;
                    margin-top: .28rem;
                    &.on{
                        background: #F65102;
                        color: #fff;
                    }
                }
            }
        }
        .describe{
            .tit{
                padding: .24rem 0 .32rem;
            }
            .describe_con{
                height: 3.12rem;
                background: #F8F8F8;
                border-radius: .08rem;
                padding: 0 .32rem;
                textarea{
                    font-size: .24rem;
                    padding: .24rem 0;
                    background: transparent;
                    border: none;
                    height: .7rem;
                    width: 100%;
                }
                .upload{
                    height: 1.6rem;
                    overflow: hidden;
                }
                .uploadPic{
                    width: 1.6rem;
                    height: 1.6rem;
                    background: url(~@/assets/img/upload-more.png) no-repeat center center/100% 100%;
                    
                }
            }
        }
        .contact{
            padding: .32rem 0;
            .tit{
                margin-bottom: .32rem;
            }
            input{
                width: 100%;
                height: .88rem;
                background: #F8F8F8;
                border-radius: .08rem;
                padding:0 .32rem;
                box-sizing: border-box;
                font-size: .24rem;
                display: block;
            }
        }
    }
    .tjBtn{
        width: 6.86rem;
        height: .96rem;
        line-height: .96rem;
        background: #F65102;
        border-radius: .48rem;
        font-size: .32rem;
        text-align: center;
        color: #fff;
        margin: 1.02rem auto 0;
    }
</style>